<script setup lang="ts"></script>

<template>
  <div class="text-center d-flex justify-center w-100 my-4">
    <div class="w-25">
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </div>
  </div>
</template>
